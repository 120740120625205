// NPM Dependencies
import { get } from 'lodash';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import { overageTotal, fullPriceBreakdownCents } from 'common/dist/price';
import { overagePriceCents } from 'common/dist/virtuals/Payment';

interface Schedule {
  drinks: Drink[];
  addOns: AddOn[];
  costBreakdownCents: any;
  menu: Menu;
  title: string;
  state: string;
}

interface Menu {
  name: string;
  numGuests: number;
  pricePerGuestCents: number;
}

interface AddOn {
  item: string;
  taxType: string;
  priceInCents: number;
  numGuests: number;
  priceType: string;
  isDeleted: boolean;
}

interface Drink {
  name: string;
  payAtTheVenue: boolean;
  numGuests: number;
  selectedDurationMinutes: number;
  costPerGuestDollars: number;
  isDeleted: boolean;
}

class CostBreakdownController {
  request: any;
  idx?: number;
  costBreakdownCents: any;
  drinks: Drink[];
  addOns: AddOn[];
  fbAndUntaxedAddOns: AddOn[];
  nonFbAddOns: AddOn[];
  menu: Menu;
  display: string;
  overages: any;
  ui = { displayOverages: false };

  constructor(private unwrapError) {
    'ngInject';
  }

  $onInit = () => {
    this.setCostBreakdown();
  }

  $onChanges = () => {
    this.setCostBreakdown();
  }

  private setCostBreakdown = () => {
    this.costBreakdownCents = fullPriceBreakdownCents(this.request);
    this.drinks = this.request.data.drinks || [];
    this.addOns = this.request.data.addOns || [];
    this.menu = this.request.data.menu || [];
    this.ui.displayOverages = !!get(this.request, 'data.payment.overages.length');
    this.overages = get(this.request, 'data.payment.overages');

    this.getFbAndUntaxedAddOns();
    this.getNonFbAddOns();
    this.filterDrinks();
  }

  public displayBreakdownTable = () => {
    return this.costBreakdownCents.menuAndDrinksTotal || this.costBreakdownCents.nonFoodAndBeverageTotal || this.drinks.length || this.addOns.length || (this.menu && this.menu.name) || this.ui.displayOverages;
  }

  public getOverageTotal = () => {
    return overageTotal(this.request);
  }

  public overagePriceCents = overagePriceCents;

  private getFbAndUntaxedAddOns = () => {
    this.fbAndUntaxedAddOns = this.addOns
      .filter(addon => addon.taxType === 'FOOD_AND_BEVERAGE' || addon.taxType === 'NONE')
      .filter(addon => !addon.isDeleted);
  }

  private getNonFbAddOns = () => {
    this.nonFbAddOns = this.addOns
      .filter(addon => addon.taxType === 'NON_FOOD_AND_BEVERAGE')
      .filter(addon => !addon.isDeleted);
  }

  private filterDrinks = () => {
    this.drinks = this.drinks.filter(drink => !drink.isDeleted);
  }
}

export const CostBreakdownComponent = {
  template: require('./cost-breakdown.component.jade'),
  controller: CostBreakdownController,
  bindings: {
    request: '<',
    idx: '<',
    display: '@'
  },
};

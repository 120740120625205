// SixPlus Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import { StateEmitter } from '../utils/StateEmitter';
import { DUser } from 'spc/lib/database/types/user';

// External Dependencies
import some from 'lodash/some';
import get from 'lodash/get';
import includes from 'lodash/includes';

export class UserService extends StateEmitter {
  token: string;
  $: DUser;
  $totalBookedEvents: number;

  constructor(
    private $api: ApiService,
    private $jwt,
    private $window,
    private $injector) {
      'ngInject';
      super(['INIT', 'LOGGED_IN', 'NOT_LOGGED_IN'], 'INIT');
      this.token = null;
      this.load();
  }

  load = () => {
    return this.$api.Auth.me().
      then((response) => {
        const data = response.data;
        if (!data || !data.user) {
          return this.$state('NOT_LOGGED_IN');
        }
        this.$ = data.user;
        this.$jwt.set(data.token);
        this.$state('LOGGED_IN');
        this.$totalBookedEvents = data.totalBookedEvents;
      }).
      catch(() => {
        this.$state('NOT_LOGGED_IN');
      });
  }

  logout = (noReload) => {
    this.$jwt.set(null);
    this.$state('NOT_LOGGED_IN');
    if (!noReload) {
      this.$window.location.reload();
    }
  }


  setUser = (user, token) => {
    this.$ = user;
    this.$jwt.set(token);
    this.$state('LOGGED_IN');
    const $analytics = this.$injector.get('$analytics');
    if (this.isAdmin()) {
      $analytics.$untrack();
    }
  }

  passwordLogin = ({ email, password, isMagicLinkAuth }) => {
    const $analytics = this.$injector.get('$analytics');
    return this.$api.Auth.login({ email, password, isMagicLinkAuth })
      .then((res) => {
        $analytics.clearReferrerData();
        res = res.data;
        if (!isMagicLinkAuth) {
          this.setUser(res.user, res.token);
        }
        return res;
      });
  }

  isHost = () => {
    return this.$ && this.$.roles && some(this.$.roles, function(role) {
      return role === 'Host';
    });
  }

  isAdmin = () => {
    return this.$ && this.$.roles && some(this.$.roles, function(role) {
      return role === 'Admin';
    });
  }

  isPremiumMember = () => {
    return this.$api.Auth.me().
      then((response) => {
        const res = response.data;
        this.$ = res.user;
        return res.isPremiumMember;
      });
  }

  isHostOrAdmin = () => {
    return this.$ && this.$.roles && some(this.$.roles, function(role) {
      return role === 'Admin' || role === 'Host';
    });
  }

  isLoggedIn = () => {
    return this.$state() === 'LOGGED_IN';
  }

  isNotLoggedIn = function isNotLoggedIn() {
    return this.$state() === 'NOT_LOGGED_IN';
  };

  isGuest = function isGuest() {
    return this.$ && this.$.roles && !this.$.roles.length;
  };

  forbidGuest = function() {
    const $location = this.$injector.get('$location');
    this.$waitFor('LOGGED_IN', function() {
      if (!this.isHostOrAdmin()) {
        $location.url('/404');
      }
    });
    this.$waitFor('NOT_LOGGED_IN', function() {
      $location.url('/login?redirect=' + $location.path());
    });
  };

  allowOnly = function(roles, cb) {
    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    const $location = this.$injector.get('$location');
    this.$waitFor('LOGGED_IN', () => {
      const userRoles = get(this, '$.roles', []);
      if (this.isAdmin()) {
        return cb ? cb(this) : undefined;
      }

      if (includes(roles, 'Guest')) {
        if (this.isGuest()) {
          return cb ? cb(this) : undefined;
        }
      }

      for (const index in userRoles) {
        if (includes(roles, userRoles[index])) {
          return cb ? cb(this) : undefined;
        }
      }

      $location.url('/404');
    });

    this.$waitFor('NOT_LOGGED_IN', function() {
      $location.url('/login?redirect=' + $location.path());
    });

  };
}

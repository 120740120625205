import { every, get, set } from 'lodash';
import { ANALYTICS_EVENTS } from 'spc/constants/ENUMS/analyticsEvents';
import { ApiService } from 'spc/shared/api/api.service';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';

class RecoRequestAuthController {
  UI_AUTH_LOGIN_TABS = {
    magicLink: 'MAGIC_LINK',
    password: 'PASSWORD'
  } as const;
  UI_AUTH_TABS = {
    login: 'LOG IN',
    register: 'REGISTER',
    checkEmail: 'CHECK EMAIL'
  } as const;
  message: string;
  authTab?: 'LOG IN' | 'REGISTER' | 'CHECK EMAIL' = this.UI_AUTH_TABS.checkEmail;
  auth: {
    isEmailValid?: boolean;
    clientEmail?: string;
    isBaseUser?: boolean;
    user?: any
    password?: string;
    errorMessage: string;
    showPassword: boolean;
    isMagicLinkSent?: boolean;
  } = {
    errorMessage: '',
    showPassword: false
  };
  setClient: ({ client, isBaseUser }) => void;
  onAuthSubmit: (submitDetails) => void;
  venueName: string;
  authLoginTab: 'MAGIC_LINK' | 'PASSWORD' = this.UI_AUTH_LOGIN_TABS.password;
  constructor(
    private $api: ApiService,
    private $user,
    private $scope,
    private unwrapError,
    private $analytics,
    private $websocket,
    private spPardot: SpPardotService
  ) {
    'ngInject';
  }

  checkEmail = () => {
    this.auth.errorMessage = '';
    this.auth.isMagicLinkSent = false;
    return this.$api.Auth.verifyEmail(this.auth.clientEmail)
      .then((res) => {
        this.auth.isEmailValid = get(res, 'data.valid');
        return this.findUser();
      })
      .then(() => {
        if (get(this.auth, 'user')) {
          this.authTab = this.auth.isBaseUser ? this.UI_AUTH_TABS.register : this.UI_AUTH_TABS.login;
        } else {
          // If no user profile, initialize a new profile with the email and set Referrer data
          set(this.auth, 'user.profile.email', this.auth.clientEmail);
          set(this.auth, 'user.internal.referrer', this.$analytics.getReferrerData());
          this.authTab = this.UI_AUTH_TABS.register;
        }

        this.setClient({ client: this.auth.user, isBaseUser: this.auth.isBaseUser });
      })
      .catch((error) => {
        this.auth.isEmailValid = get(error, 'data.valid');
        if (!this.auth.isEmailValid) {
          this.auth.errorMessage = `The email is invalid. Please check to make sure there's no typo.`;
        }
        this.unwrapError(error);
      });
  }

  changeLoginAuthTab = (tab) => {
    this.auth.errorMessage = '';
    this.authLoginTab = tab;
  }

  backToEmail = () => {
    this.authTab = this.UI_AUTH_TABS.checkEmail;
  }

  findUser = () => {
    return this.$api.Auth.findUser(this.auth.clientEmail)
      .then((userData) => {
        this.auth = { ...this.auth, ...userData };
      })
      .catch(error => this.unwrapError(error));
  }

  loginWithPassword = () => {
    this.auth.errorMessage = '';
    return this.$user.passwordLogin({ email: this.auth.clientEmail, password: this.auth.password, isMagicLinkAuth: false })
      .then((res) => {
        return this.handleLoginSuccess();
      })
      .catch((error) => {
        this.auth.errorMessage = error.data.error;
        this.unwrapError(error);
      });
  }

  loginWithMagicLink = () => {
    this.auth.errorMessage = '';
    this.auth.isMagicLinkSent = false;
    return this.$user.passwordLogin({ email: this.auth.clientEmail, password: '', isMagicLinkAuth: true })
      .then((res) => {
        this.$websocket.initAuthWsConnection({ user: res.user, handleSuccess: this.handleLoginSuccess });
        this.auth.isMagicLinkSent = true;
      })
      .catch((error) => {
        this.auth.errorMessage = error.data.error;
        this.unwrapError(error);
      });
  }

  handleLoginSuccess = () => {
    this.$analytics.$trackEvent(ANALYTICS_EVENTS.auth['loggedIn']);
    this.spPardot.trackUser({ event: 'obscured venue login' });
  }

  checkRequiredFields = (isBaseUser) => {
    const commonFields = [
      get(this.auth, 'user.profile.name.first'),
      get(this.auth, 'user.profile.name.last'),
      get(this.auth, 'user.profile.email')
    ];

    const requiredFields = isBaseUser
      ? commonFields
      : [...commonFields, get(this.auth, 'password')];

    return every(requiredFields);
  }

  toggleShowPassword = () => {
    return this.auth.showPassword = !this.auth.showPassword;
  }

}

export const RecoRequestAuthComponent = {
  template: require('./reco-request-auth.component.jade'),
  controller: RecoRequestAuthController,
  bindings: {
    setClient: '&',
    onAuthSubmit: '&',
    venueName: '<'
  }
};

// npm deps
import get from 'lodash/get';
import set from 'lodash/set';
import capitalize from 'lodash/capitalize';

// sixplus services
import { ApiService } from 'spc/shared/api/api.service';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';
import { UserService } from 'spc/services/user.service';
import AnalyticsService from 'spc/shared/analytics/analytics.service';
import { ToastService } from 'spc/shared/toast.service';

// sixplus types
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { RawUser } from 'spc/lib/database/types/user';
import { RawLead } from 'spc/lib/database/types/lead';
import { DCompany, RawCompany } from 'spc/lib/database/types/company';


// helpers and utils
import { isActualUser } from 'common/dist/virtuals/User';
import { UNSAFE_restoreLegacyJqLiteXHTMLReplacement } from 'angular';

// constant
import { ANALYTICS_EVENTS } from '../constants/ENUMS/analyticsEvents';

// external dependencies
import ENUMS from 'common/dist/enums';
import { every, debounce, cloneDeep } from 'lodash';

class ObscuredLoginController {
  cities: string[];
  close: ({ user, isNewUser }: { user: RawUser, isNewUser?: boolean }) => any;

  primaryUser: RawBaseUser;
  lead: RawLead;
  isNewUser: boolean;
  premiumRegistrationRequest: boolean;
  companies: RawCompany[] = [];
  company: DCompany;
  showCompany: boolean = false;
  loading: boolean = false;
  UI_TABS = {
    completeRegistration: 'COMPLETE REGISTRATION',
    login: 'LOG IN',
    register: 'REGISTER',
    checkEmail: 'CHECK EMAIL',
    magicLinkSuccess: 'MAGIC_LINK_SUCCESS'
  } as const;
  ui: {
    error?: string;
    disableRegisterButton: boolean;
    wantsNewsletter: boolean;
    tab: 'COMPLETE REGISTRATION' | 'LOG IN' | 'REGISTER' | 'CHECK EMAIL' | 'MAGIC_LINK_SUCCESS'
    validEmail?: boolean;
  } = {
    disableRegisterButton: false,
    wantsNewsletter: true,
    tab: this.UI_TABS.completeRegistration,
    validEmail: true
  };

  auth: {
    validEmail?: boolean;
    clientEmail?: string;
    isBaseUser?: boolean;
    company?: {
      name: string,
      domain: []
    };
    user?: {
      profile: {
        name: {
          first: string;
          last: string;
        },
        email: string;
        newsletters?: string[];
        city?: string;
      }
    };
    citySelected?: string;
    password?: string;
    errorMessage?: string;
  } = { };

   constructor(private $user, private $api: ApiService, private unwrapError, private $analytics, private spPardot: SpPardotService, private $websocket, private toast: ToastService) {
    'ngInject';
    this.cities = ENUMS.acceptableUserCities;
  }

  $onInit = () => {
    if (!this.primaryUser) {
      this.changeUser();
    } else {
      this.primaryUser.profile.newsletters = ['Main'];
      if (isActualUser(this.primaryUser)) {
        this.ui.tab = this.UI_TABS.checkEmail;
      }
    }
  }

  select = ({ slug, payload }: { slug: 'company', payload: RawCompany | RawBaseUser | any }) => {
    if (slug === 'company') {
      this.auth.company.name = payload.name;
      this.companies = [];
    }
  }

  createCompany = () => {
    return this.$api.Companies.createCompany({ name: this.auth.company.name })
      .then((data) => {
          this.showCompany = false;
          this.select({ slug: 'company', payload: data.company });
      })
      .catch(error => this.unwrapError(error));
  }

  getSearchResult = ({ slug }: { slug: 'company'}) => {
    if (slug === 'company') {
      const name = this.auth.company.name;
      return this.$api.Companies.searchCompanies(name)
        .then((response) => {
          this.companies = cloneDeep(response.companies);
          this.showCompany = !this.companies.length;
        }).catch((err) => {
            this.unwrapError(err);
      });
    }
  }

  debounceSearch = debounce(this.getSearchResult, 300);

  isPrimaryClient = () => {
    return this.$user.$ && this.$user.$._id === this.primaryUser._id;
  }

  changeUser = () => {
    this.ui.tab = this.UI_TABS.checkEmail;
  }

  backOriginalUser = () => {
    this.ui.tab = this.UI_TABS.completeRegistration;
  }

  checkEmail = () => {
    this.auth.errorMessage = '';

    return this.$api.Auth.verifyEmail(this.auth.clientEmail)
      .then((res) =>  {
        this.auth.validEmail = get(res, 'data.valid');
      })
      .then(() => this.findUser())
      .then(() => {
        if (this.auth.user && !this.auth.isBaseUser) {
          this.ui.tab = this.UI_TABS.login;
        } else {
          if (!this.auth.user && !this.auth.isBaseUser) {
            this.isNewUser = true;
            set(this.auth, 'user.profile.email', this.auth.clientEmail);
          } else if (!this.auth.user) {
            set(this.auth, 'user.profile.email', this.auth.clientEmail);
          }
          // If there is a user who is a base user
          // or if there is no user
          // both should be taken to the registration page
          return this.ui.tab = this.UI_TABS.register;
        }
      })
      .catch((error) => {
        this.auth.validEmail = error.data.valid;
        this.unwrapError(error);
      });
  }

  findUser = () => {
    return this.$api.Auth.findUser(this.auth.clientEmail)
      .then((data) => {
        this.auth.isBaseUser = data.isBaseUser;
        this.auth.user = data.user;
        this.auth.password = '';
      });
  }

  checkPrimaryUser = () => {
    this.auth.clientEmail = this.primaryUser.profile.email;
    this.checkEmail()
      .catch((error) => {
        this.ui.error = 'Invalid email address or user does not exist.';
        this.unwrapError(error);
      });
  }

  register = () => {
    if (this.ui.wantsNewsletter && this.auth.user.profile.city) {
      this.auth.user.profile.newsletters = ['Main', this.auth.user.profile.city];
    }
    this.auth.errorMessage = '';
    this.ui.disableRegisterButton = true;
    this.loading = true;

    const userData = {
      guest: this.auth.user,
      company: this.auth.company,
      password: this.auth.password
    };
    this.loading = true;

    return this.$api.Auth.register(userData)
      .then((res) => {
        this.handleRegisterSuccess(res.data);
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.data || error.message) {
          this.auth.errorMessage = get(error, 'data.error.message') || get(error, 'error.message');
          this.ui.disableRegisterButton = false;
        }
        this.unwrapError(error);
      });
  }

  sendRegistrationMagicLink = function () {
    this.auth.errorMessage = '';

    return this.$api.Auth.registerWithMagicLink({ user: this.auth.user })
      .then((res) => {
        this.$websocket.initAuthWsConnection({ user: res.data.user, handleSuccess: this.handleRegisterSuccess });
        this.ui.tab = this.UI_TABS.magicLinkSuccess;
        this.toast.goodNews('Success', 'Verification link sent to your email');
      })
      .catch((error) => {
        this.auth.errorMessage = error.data.message;
        this.unwrapError(error);
      });
  };

  handleRegisterSuccess = ({ user, token, provider }) => {
    try {
      this.$user.setUser(user, token, provider);
      // Analytics - register (alias) user, then identify
      this.$analytics.$register(this.$user.$);
        const eventName = provider ?
          'registered' + capitalize(provider) :
          'registered';
       this.$analytics.$trackEvent(ANALYTICS_EVENTS.auth[eventName]);
       this.spPardot.trackUser({ event: 'Recos Login Modal' });

      return this.close({ user: this.$user.$, isNewUser: true });
    } catch (error) {
      this.close({ user: this.$user.$, isNewUser: this.isNewUser });
      this.unwrapError(error);
    }
  }

  loginWithMagicLink = () => {
    if (!this.auth.validEmail) {
      return;
    }
    this.auth.errorMessage = '';
    this.auth.password = '';

    return this.$user.passwordLogin({ email: this.auth.clientEmail, password: this.auth.password, isMagicLinkAuth: true })
      .then((res) => {
        this.$websocket.initAuthWsConnection({ user: res.user, handleSuccess: this.handleLoginSuccess });
        this.ui.tab = this.UI_TABS.magicLinkSuccess;
        this.toast.goodNews('Success', 'Magic link has been sent to your email successfully');
      })
      .catch((error) => {
        this.auth.errorMessage = error.data.error;
        this.unwrapError(error);
      });
  }

  loginWithPassword = () => {
    if (!this.auth.validEmail) {
      return;
    }
    this.auth.errorMessage = '';

    return this.$user.passwordLogin({ email: this.auth.clientEmail, password: this.auth.password, isMagicLinkAuth: false })
      .then(() => {
        return this.handleLoginSuccess();
      })
      .catch((error) => {
        this.auth.errorMessage = error.data.error;
        this.unwrapError(error);
      });
  }

  handleLoginSuccess = () => {
    // Analytics - login user
    this.$analytics.$trackEvent(ANALYTICS_EVENTS.auth['loggedIn']);
    this.spPardot.trackUser({ event: 'Recos Login Modal' });

    return this.close({ user: this.$user.$, isNewUser: false });
  }

  selectPrimaryCity = (city) => {
    this.auth.user.profile.city = city.value;
    this.auth.citySelected = city.name;
  }

  toggleNewsletters = () => {
    this.ui.wantsNewsletter = !this.ui.wantsNewsletter;
    const newsletters = this.ui.wantsNewsletter ? ['Main'] : [];
    this.auth.user.profile.newsletters = newsletters;
  }

  hasEnteredAllFields = function () {
    const requiredFields = [
      get(this.auth, 'user.profile.name.first'),
      get(this.auth, 'user.profile.name.last'),
      get(this.auth, 'user.profile.email'),
      get(this.auth, 'password'),
      get(this.auth, 'user.profile.city')
    ];
    if (!this.auth.isBaseUser) {
      return every(requiredFields);
    } else {
      return true;
    }
  };
}

export const ObscuredLoginComponent = {
  template: require('./obscured-login.component.jade'),
  controller: ObscuredLoginController,
  bindings: {
    primaryUser: '<',
    lead: '<',
    premiumRegistrationRequest: '<',
    close: '&'
  }
};

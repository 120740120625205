import { DUser, RawUser } from 'spc/lib/database/types/user';
import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';
import AnalyticsService from 'spc/shared/analytics/analytics.service';
import ENUMS from 'common/dist/enums';
import set from 'lodash/set';
import get from 'lodash/get';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { ToastService } from 'spc/shared/toast.service';

/**
 * Use Cases
 *
 * user is unauthenticated in all cases
 *
 * 1. User enters client's email
 *   - Lead's client is not registered
 *   - Lead's client is registered
 * 2. User does not enter client's email
 *   - User has a registered account
 *   - User does not have a registered account
 *
 */

class LeadAuthenticationController {
  state: 'email' | 'registration' | 'login' = 'email';
  lead: { clients: { user: RawBaseUser }[] };
  cities: { name: string, value: string }[];
  close: () => any;
  auth: {
    isBaseUser: boolean;
    user: RawUser;
    email: string;
    isValid: boolean;
    password: string;
    repeatPassword?: string;
    errorMessage: string;
  };
  data: {
    password: string;
    showPassword: boolean;
  };

  constructor(
    private $api: ApiService,
    private unwrapError,
    private $analytics: AnalyticsService,
    private $user: UserService,
    private spPardot: SpPardotService,
    private $websocket,
    private toast: ToastService,
    private $scope
    ) {
    'ngInject';
    this.cities = ENUMS.acceptableUserCities;
  }

  submitEmail = () => {
    this.$api.Auth.verifyEmail(this.auth.email)
      .then((response) => {
        this.auth.isValid = get<boolean>(response, 'data.valid');
      })
      .then(() => this.findUser())
      .then(() => {
        if (this.auth.user && !this.auth.isBaseUser) {
          return this.state = 'login';
        } else {
          if (!this.auth.user) {
            set(this.auth, 'user.profile.email', this.auth.email);
          }
          // If there is a user who is a base user
          // or if there is no user
          // both should be taken to the registration page
          return this.state = 'registration';
        }
      })
      .catch((error) => {
        this.auth.isValid = error.data.valid;
        this.unwrapError(error);
      });
  }

  loginWithPassword = () => {
    this.auth.errorMessage = '';
    if (!this.auth.isValid) {
      return;
    }
    this.$user.passwordLogin({ email: this.auth.email, password: this.auth.password, isMagicLinkAuth: false })
      .then(() => this.close())
      .catch((error) => {
        this.auth.errorMessage = error.data.error;
        this.unwrapError(error);
      });
  }

  loginWithMagicLink = () => {
    this.auth.errorMessage = '';
    return this.$user.passwordLogin({ email: this.auth.email, password: '', isMagicLinkAuth: true })
      .then((res) => {
        this.$websocket.initAuthWsConnection({ user: res.user, $scope: this.$scope });
        this.toast.goodNews('Success', 'A magic link has been sent to your email. Click on the link in the email to verify and log in to your account.');
        this.close();
      })
      .catch((error) => {
        this.auth.errorMessage = error.data.error;
        this.unwrapError(error);
      });
  }

  register = () => {
    this.auth.errorMessage = '';
    this.$api.Auth.register({
      guest: this.auth.user,
      password: this.auth.password,
      referrer: this.$analytics.getReferrerData()
    }).then((response) => {
      const { data: { user, token } }  = response;
      this.$analytics.clearReferrerData();
      this.$user.setUser(user, token);
      this.spPardot.trackUser({ user, event: 'reco registration' });
      this.close();
    }).catch((error) => {
      this.auth.errorMessage = error.data.error.message;
      this.unwrapError(error);
    });
  }

  findUser = () => {
    return this.$api.Auth.findUser(this.auth.email)
      .then((data) => {
        this.auth.isBaseUser = data.isBaseUser;
        this.auth.user = data.user;
        this.auth.password = '';
      });
  }

  isClientEmail = (email) => {
    const emails = this.lead.clients.map(client => client.user.profile.email);

    return emails.includes(email.toLowerCase());
  }

  findPrimaryCity = (cityValue) => {
    const cityObj = this.cities.find(city => city.value === cityValue);
    if (cityObj) {
      return cityObj.name;
    }
  }

  selectPrimaryCity = (city) => {
    set(this.auth, 'user.profile.city', city);
  }

  sendRegistrationMagicLink = function () {
    this.auth.errorMessage = '';

    return this.$api.Auth.registerWithMagicLink({ user: this.auth.user })
      .then((res) => {
        this.$websocket.initAuthWsConnection({ user: res.user, $scope: this.$scope });
        this.toast.goodNews('Success', 'A magic link has been sent to your email. Click on the link in the email to verify and log in to your account.');
        this.close();
      })
      .catch((error) => {
        this.ui.error = error.data.error;
        this.unwrapError(error);
      });
  };

}

export const leadAuthenticationComponent = {
  template: require('./lead-authentication.component.jade'),
  controller: LeadAuthenticationController,
  bindings: {
    lead: '<',
    close: '&'
  }
};

const facebook = require('facebook-oauth-agent');
const linkedin = require('linkedin-oauth-agent');
import { StateEmitter } from '../utils/StateEmitter';
// constant
import { ANALYTICS_EVENTS } from '../constants/ENUMS/analyticsEvents';
import { ApiService } from 'spc/shared/api/api.service';
import { ToastService } from 'spc/shared/toast.service';

module.exports = function() {
  return {
    template: require('./login.jade'),
    scope: {
      isEmbedded: '='
    },
    controller: ['$scope', '$user', 'ENUMS', '$timeout', '$analytics', 'spPardot', '$location', '$api', 'toast', '$websocket', function($scope, $user, ENUMS, $timeout, $analytics, spPardot, $location, $api: ApiService, toast: ToastService, $websocket) {
      $scope.LOGIN_UI_TABS = {
        passwordLogin: 'PASSWORD_LOGIN',
        magicLinkLogin: 'MAGIC_LINK_LOGIN',
        baseUserRegistration: 'BASE_USER_REGISTRATION',
        magicLinkSuccess: 'MAGIC_LINK_SUCCESS',
        passwordSetupLinkSuccess: 'PASSWORD_SETUP_LINK_SUCCESS'
      } as const;
      $scope.user = $user;
      $scope.email = '';
      $scope.password = '';
      $scope.ui = {
        tab: $scope.LOGIN_UI_TABS.passwordLogin
      };
      $scope.showPassword = false;

      const $ = $scope.$ =
        new StateEmitter(['INIT', 'SUCCESS', 'FAIL', 'LOGGED_IN', 'BASE_USER'], '');

      const userStates = $user.stream();
      userStates.on('LOGGED_IN', function() {
        if ($.$state() !== 'SUCCESS') {
          $.$state('LOGGED_IN');
          $scope.$apply();
        }
      });
      userStates.on('NOT_LOGGED_IN', function() {
        $.$state('INIT');
        $scope.$apply();
      });

      $scope.emitEvent = emitEvent;

      function emitEvent(eventName, params) {
        $scope.$emit(eventName, params);
      }

      function determineEvent () {
        const location = this.spPardot.determineLocation();
        return location ? `${ location } login` : 'login page';
      }

      function handleLogin (isMagicLinkAuth: boolean) {
        $scope.error = '';
        if ($.$state() === 'LOGGED_IN' || $.$state() === 'SUCCESS') {
          return;
        }
        // Analytics - clicked login
        const eventName = ANALYTICS_EVENTS.auth.clicked.login;
        $analytics.$trackEvent(eventName);

        $scope.handlePasswordManagers();
        return $user.passwordLogin({ email: $scope.email, password: $scope.password, isMagicLinkAuth })
          .catch(function (error) {
            if (error.data.user) {
              $.$state('BASE_USER');
              $scope.user = error.data.user;
              $scope.changeTabAndState({ tab: $scope.LOGIN_UI_TABS.baseUserRegistration });
            } else {
              $scope.error = error.data.error;
              $.$state('FAIL');
            }
          });
      }

      $scope.loginWithMagicLink = async function () {
        const isMagicLinkAuth = true;
        $scope.password = '';
        return handleLogin(isMagicLinkAuth)
          .then((response) => {
            if (!response) {
              return;
            }
            $scope.user = response.user;
            $websocket.initAuthWsConnection({ user: $scope.user, handleSuccess: handleLoginSuccess });
            $scope.changeTabAndState({ tab: $scope.LOGIN_UI_TABS.magicLinkSuccess });
            toast.goodNews('Success', 'Check your inbox for the magic link!');
          })
          .catch((error) => {
            $scope.error = error.data.error;
          });
      };

      $scope.loginWithPassword = function () {
        const isMagicLinkAuth = false;
        return handleLogin(isMagicLinkAuth)
          .then((response) => {
            if (!response) {
              return;
            }
            return handleLoginSuccess(response);
          })
          .catch((error) => {
            $scope.error = error.data.error;
          });
      };

      function handleLoginSuccess (response) {
        $.$state('SUCCESS');
        $scope.$emit('LOGIN_SUCCESS', response);
        spPardot.trackUser({ event: determineEvent() });
      }

      function handleRegisterSuccess (response) {
        $.$state('SUCCESS');
        $scope.$emit('REGISTER_SUCCESS', response);
        spPardot.trackUser({ event: determineEvent() });
      }

      $scope.sendRegistrationMagicLink = function () {
        $scope.error = '';

        return $api.Auth.registerWithMagicLink({ user: $scope.user })
        .then((res) => {
          $websocket.initAuthWsConnection({ user: res.data.user, handleSuccess: handleRegisterSuccess });
          $scope.changeTabAndState({ tab: $scope.LOGIN_UI_TABS.magicLinkSuccess });
          toast.goodNews('Success', 'Verification link sent to your email');
        })
        .catch((error) => {
          $scope.error = error.data.error;
        });
      };

      $scope.sendRegistrationPasswordLink = function () {
        $scope.error = '';

        return $api.Auth.setupPasswordWithLink($scope.user.profile.email)
          .then(() => {
            $scope.changeTabAndState({ tab: $scope.LOGIN_UI_TABS.passwordSetupLinkSuccess });
            toast.goodNews('Success', 'Registration link sent to your email');
          })
          .catch((error) => {
            $scope.error = error.data.error;
          });
      };

      $scope.linkedinLogin = function() {
        $user.linkedinLogin(function(error, res) {
          if (!error) {
            spPardot.trackUser({ event: determineEvent() });
            $scope.$emit(res.isNew ? 'REGISTER_SUCCESS' : 'LOGIN_SUCCESS',
              res);
          }
        });
      };

      $scope.facebookLogin = function() {
        $user.facebookLogin(function(error, res) {
          if (!error) {
            spPardot.trackUser({ event: determineEvent() });
            $scope.$emit(res.isNew ? 'REGISTER_SUCCESS' : 'LOGIN_SUCCESS',
              res);
          }
        });
      };

      $timeout(function() {
        $scope.$emit('LoginController');
      }, 0);

      $scope.changeTabAndState = function ({ tab, state }) {
        if (state) {
          $.$state(state);
        }
        $scope.error = '';
        $scope.ui.tab = tab;
      };

      $scope.toggleShowPassword = function () {
        return $scope.showPassword = !$scope.showPassword;
      };
    }],
    link: function(scope, element, attrs) {
      scope.handlePasswordManagers = function () {
        scope.email = element.find('#loginEmail').val();
        scope.password = element.find('#loginPassword').val();
      };
    }
  };
};

module.exports = function() {
  return {
    template: require('./magic-link-verification.jade'),
    controllerAs: '$ctrl',
    controller: ['$scope', '$location', '$api', '$routeParams', 'unwrapError', 'spPardot', function ($scope, $location, $api, $routeParams, unwrapError, spPardot) {
      this.token = $routeParams.token;
      this.operation = $routeParams.operation;
      this.loading;
      this.error = '';

      this.init = () => {
        if (!this.token || !this.operation) {
          this.error = 'Oops! It looks like the link has expired or is invalid. Please request a new one to continue.';
          return;
        }

        return this.verifyUserMagicToken();
      };

      this.verifyUserMagicToken = () => {
        this.loading = true;
        this.error = '';

        // Verifying magic link token
        return $api.Auth.verifyMagicLinkToken({ token: this.token, operation: this.operation })
          .then(({ data: authData }) => {
            this.loading = false;
            $scope.user.setUser(authData.user, authData.token, authData.provider);

            // handle redirect and emits after token verified successfully
            if (authData.operation === 'register') {
              $location.url('/welcome');
            } else if (authData.operation === 'login') {
              $location.url('/');
            }
          })
          .catch((error) => {
            this.loading = false;
            this.error = error.data.message;
            unwrapError(error);
          });
      };

      this.init();
  }]
  };
};

import io from 'socket.io-client';

export class WebSocketService {
  constructor(
    private unwrapError,
    private $user,
    private $config
  ) {
    'ngInject';
  }

  /**
   * Initiates a WebSocket connection to perform real-time user magic link authentication.
   * Upon successful verification, the user state is updated, appropriate success events are emitted,
   * and redirection occurs based on the operation (registration or login).
   * The function also handles disconnections and error management.
   *
   * @param {Object} params - The function parameters.
   * @param {Object} params.user - The user object containing the user's ID and email.
   * @param {Object} params.$scope - The AngularJS scope used to emit success events.
   */
  initAuthWsConnection = ({ user, handleSuccess, $scope }: { user: any, handleSuccess: (verifiedData) => void, $scope?: any }) => {
    try {
      const { _id: userId } = user;
      console.log('$config.basePath.url', this.$config.basePath.url);
      const socket = io(this.$config.basePath.url, {
        path: '/socket.io',
        // transports: ['websocket'],
        secure: true,
        reconnectionAttempts: 5
      });

      // Establish WebSocket connection and send userId to server
      socket.on('connect', () => {
        console.log(socket)
        console.log('Client socket connected ID:', socket.id);
        socket.emit('userAuthentication', userId);
      });

      // Listen for errors
      socket.on('connect_error', (err) => {
        console.error('Connection error:', err);
      });

      socket.on('error', (error) => {
        console.error('Socket error:', error);
      });

      // Handle incoming messages for user verification
      socket.on('userVerified', (verifiedData) => {
        const { user: verifiedUser, token, operation, provider } = verifiedData;

        if (!(verifiedUser && token) && (userId !== verifiedUser._id.toString())) {
          return;
        }

        // Set user data and handle success
        this.$user.setUser(verifiedUser, token, provider);
        return handleSuccess(verifiedData);
      });

      // Handle WebSocket disconnection
      socket.on('disconnect', () => {
        if (socket.connected) {
          socket.disconnect();
        }
      });

    } catch (error) {
      this.unwrapError(error);
    }
  }
}
